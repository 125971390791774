<script setup lang="ts">
import {nextTick, PropType, ref, watch} from "vue";
import {useMixin} from "@/mixins";

const props = defineProps({
    selectedPlan: {
        type: Object as PropType<App.Models.Plan | null>,
        default: null,
    },
});

const emit = defineEmits(['close']);

const closeOverlay = () => {
    emit('close');
};

const calculatedProducts = ref<Array<{ label: string; type: string; count: number; costPerCard: number }>>([]);
const shippingCosts = ref<number>(0);
const totalCosts = ref<number>(0);

const calculateCosts = (plan: App.Models.Plan) => {
    calculatedProducts.value = [];
    shippingCosts.value = 0;
    totalCosts.value = 0;

    calculatedProducts.value.push({
        label: __('features.save_the_date_cards'),
        type: 'saveTheDate',
        count: 35,
        costPerCard: 2.50,
    });

    totalCosts.value += 35 * 2.50;
    shippingCosts.value += 0.85 * 35;

    calculatedProducts.value.push({
        label: __('features.invitation_cards'),
        type: 'invitation',
        count: 35,
        costPerCard: 4.50,
    });

    totalCosts.value += 35 * 4.50;
    shippingCosts.value += 0.85 * 35;

    if (plan.features?.includes('thank_you_cards')) {
        calculatedProducts.value.push({
            label: __('features.thank_you_cards'),
            type: 'thank_you',
            count: 35,
            costPerCard: 2.50,
        });

        totalCosts.value += 35 * 2.50;
        shippingCosts.value += 0.85 * 35;
    }

    totalCosts.value += shippingCosts.value;
}

const {__} = useMixin();

if (props.selectedPlan) {
    calculateCosts(props.selectedPlan);
}
watch(() => props.selectedPlan, (selectedPlan) => {
    if (!selectedPlan) return;

    calculateCosts(selectedPlan);
});
</script>

<template>
    <div v-if="props.selectedPlan" class="mt-4 flex flex-col justify-between">
        <div>
            <div class="mb-4 font-display font-black text-[28px] text-[#4B1B1B] leading-[120%]">
                Das könnt ihr mit einer digitalen Einladung zur klassischen sparen
            </div>

            <div class="font-body text-[16px] text-[#4B1B1B] leading-[150%]">
                Die dargestellten Zahlen basieren auf echten Preisen von klassischen
                Einladungskarten-Anbietern, um euch realistische Einsparungen zeigen zu können.
            </div>

            <div class="mt-12">
                <div class="font-display font-black text-[#4B1B1B] text-[24px] leading-[120%]">
                    Klassische Karten
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]" v-for="product in calculatedProducts"
                     :key="product.type">
                    <div class="flex justify-between py-[18px]">
                        <div>
                            {{ product.label }}
                        </div>

                        <div>
                            {{ product.count }} Stück (á {{
                                new Intl.NumberFormat('de', {
                                    currency: 'EUR',
                                    style: 'currency',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(Number(product.costPerCard))
                            }})
                        </div>
                    </div>
                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]">
                    <div class="flex justify-between py-[18px]">
                        <div>
                            Porto für Briefe
                        </div>

                        <div>
                            {{
                                new Intl.NumberFormat('de', {
                                    currency: 'EUR',
                                    style: 'currency',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(Number(shippingCosts))
                            }}
                        </div>
                    </div>
                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]">
                    <div class="flex justify-between py-[18px]">
                        <div>
                            Kosten insgesamt
                        </div>

                        <div class="font-extrabold">
                            {{
                                new Intl.NumberFormat('de', {
                                    currency: 'EUR',
                                    style: 'currency',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(Number(totalCosts))
                            }}
                        </div>
                    </div>
                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>
            </div>

            <div class="mt-10">
                <div class="font-display font-black text-[#4B1B1B] text-[24px] leading-[120%]">
                    Digitale Mi Boda Karten
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]">
                    <div class="flex justify-between py-[18px]">
                        <div>
                            Save The Date
                        </div>

                        <div>
                            Beliebig viele
                        </div>
                    </div>

                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]">
                    <div class="flex justify-between py-[18px]">
                        <div>
                            Hochzeitskarten
                        </div>

                        <div>
                            Beliebig viele
                        </div>
                    </div>

                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]">
                    <div class="flex justify-between py-[18px]">
                        <div>
                            Dankeskarten
                        </div>

                        <div>
                            Beliebig viele
                        </div>
                    </div>

                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]" v-if="selectedPlan.is_discounted">
                    <div class="flex justify-between py-[18px]">
                        <div>
                            Rabatt
                        </div>

                        <div class="font-extrabold text-[#DA1E28]">
                            - 10,00 &euro;
                        </div>
                    </div>

                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]">
                    <div class="flex items-start justify-between py-[18px]">
                        <div>
                            Einmalige Kosten
                        </div>

                        <div>
                            <div class="font-black text-[24px] text-[#63CA68]">
                                {{
                                    new Intl.NumberFormat('de', {
                                        currency: 'EUR',
                                        style: 'currency',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(Number(selectedPlan.price))
                                }}
                            </div>

                            <div class="text-[13px] text-[#4B1B1B] text-right"
                                 v-if="selectedPlan.is_discounted">
                                statt <span class="line-through"> {{
                                    new Intl.NumberFormat('de', {
                                        currency: 'EUR',
                                        style: 'currency',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(Number(selectedPlan.price + 10))
                                }}</span>
                            </div>
                        </div>
                    </div>

                    <hr class="w-full bg-[#CB7373] opacity-20"/>
                </div>

                <div class="font-body text-[15px] text-[#4B1B1B]">
                    <div class="flex items-center justify-between py-[18px]">
                        <div>
                            Das könnt ihr sparen
                        </div>

                        <div class="font-extrabold text-[#DA1E28] text-[29px]">
                            {{
                                new Intl.NumberFormat('de', {
                                    currency: 'EUR',
                                    style: 'currency',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(Number(totalCosts - (selectedPlan?.price ?? 0)))
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="relative mb-[40px] mt-[10px] bg-[#FFF0F0] font-body text-[#4B1B1B] leading-[150%] text-[15px] rounded-[8px] p-4">

                <div class="absolute right-[24px] top-[-12px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.17157 2.82843C10.7337 1.26633 13.2663 1.26633 14.8284 2.82843L21.1716 9.17157C22.7337 10.7337 22.7337 13.2663 21.1716 14.8284L14.8284 21.1716C13.2663 22.7337 10.7337 22.7337 9.17157 21.1716L2.82843 14.8284C1.26633 13.2663 1.26633 10.7337 2.82843 9.17157L9.17157 2.82843Z"
                            fill="#FFF0F0"/>
                    </svg>
                </div>

                <strong class="font-body font-bold text-[15px] text-[#DA1E28] underline">
                    Glückwunsch! Ihr spart {{
                        new Intl.NumberFormat('de', {
                            currency: 'EUR',
                            style: 'currency',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(Number(totalCosts - selectedPlan.price))
                    }}
                </strong> dank unserer digitalen Einladungslösung im Vergleich zu Klassischen
                Einladung
                und
                tut auch noch etwas gutes für die Umwelt.
            </div>
        </div>

        <div class="mb-4 relative -mr-4 -ml-4 px-4">
            <div class="absolute inset-x-0 h-px bg-[#CB7373] opacity-20"></div>

            <div class="mt-5 button button-primary w-full py-[18px]"
                 @click="closeOverlay">
                Okay, verstanden
            </div>
        </div>
    </div>
</template>
